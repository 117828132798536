<template>
  <base-container>
    <template v-slot:header>
      <v-btn class="mx-2" @click="goBack" color="accent" :disabled="loading"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        @click="validate"
        :disabled="!valid || loading || !editable"
        color="accent"
        >Salvar</v-btn
      >
    </template>
    <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
      <v-card :loading="loading">
        <v-card-title
          ><span>Dados</span>
          <v-spacer></v-spacer>
          <v-btn
            class="elevation-2"
            @click="editable = !editable"
            color="deep-orange lighten-1"
            dark="dark"
            fab="fab"
            small="small"
            v-if="!inserting"
          >
            <v-icon>{{ editable ? "mdi-close" : "mdi-pencil" }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :disabled="!editable"
                :rules="[v => !!v || 'Campo obrigarório']"
                @keypress.enter="$refs.code.focus()"
                label="Descrição"
                ref="name"
                v-model="doc.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :disabled="!editable"
                :error-messages="codeError"
                @focus="codeError = null"
                @keypress.enter="$refs.price.focus()"
                label="Código"
                ref="code"
                v-model="doc.code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :disabled="!editable"
                @keypress.enter="$refs.stock.focus()"
                label="Valor (R$)"
                placeholder="0,00"
                ref="price"
                type="tel"
                v-currency="priceOptions"
                v-model="doc.price"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :disabled="!editable"
                @keypress.enter="$refs.stock.blur()"
                label="Estoque"
                placeholder="0,000"
                ref="stock"
                type="tel"
                v-currency="stockOptions"
                v-model="doc.stock"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                :disabled="!editable"
                auto-grow="auto-grow"
                filled="filled"
                label="Observações"
                row-height="20"
                rows="2"
                v-model="doc.comments"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="!inserting">
          <v-spacer></v-spacer>
          <v-btn @click="deleteItem" color="red" text="text"
            >Excluir item</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";

import { db } from "../../plugins/google/firebase";
import { parseCurrency, setValue } from "vue-currency-input";
import $store from "@/store";
import firestore from "../../plugins/firestore";

const { COLLECTION_ITEMS, COLLECTION_DOCUMENTS_ITEMS } = $store.getters,
  SOURCE = { source: "cache" },
  defaultDoc = {
    code: "",
    comments: "",
    name: "",
    price: "",
    stock: "",
  };

export default {
  name: "item-edit",
  components: { BaseContainer },
  props: ["type"],

  data: () => ({
    editable: false,
    loading: false,
    inserting: true,
    valid: true,
    docRef: null,
    // firestore doc
    doc: Object.assign({}, defaultDoc),

    codeError: null,
  }),

  created() {
    this.inserting = this.type === "add";
  },

  mounted() {
    this.onStart();
  },

  computed: {
    priceOptions() {
      return {
        autoDecimalMode: true,
        currency: null,
        precision: 2,
      };
    },
    stockOptions() {
      return {
        autoDecimalMode: true,
        currency: null,
        precision: 3,
      };
    },
  },

  methods: {
    deleteItem() {
      const deleteAction = async () => {
        try {
          this.loading = true;
          const items = await db
            .collection(COLLECTION_DOCUMENTS_ITEMS)
            .where("itemId", "==", this.docRef.id)
            .get();

          if (items.size > 0) {
            throw { items: "Item vinculado à venda, impossível excluir" };
          }

          await this.docRef.delete();

          this.$snackbar.show({ title: "Item excluído com sucesso" });
          this.goBack();
        } catch (e) {
          this.$messages.error({ text: e.items || "Não foi possível excluir" });
        } finally {
          this.loading = false;
        }
      };

      this.$dialog.show(
        "Excluir item",
        "Se o item estiver vinculado a agendamentos e vendas, não será possível excluir. Tem certeza que deseja excluir?",
        [
          { text: "cancelar" },
          { text: "excluir", style: "destrutive", onClick: deleteAction },
        ],
      );
    },

    goBack() {
      this.$router.go(-1);
    },

    getDataFromApi(docRef) {
      this.docRef = db.collection(COLLECTION_ITEMS).doc(docRef);

      this.docRef
        .get(SOURCE)
        .then((snapshot) => {
          const doc = snapshot.data();
          this.doc = Object.assign(
            {},
            {
              code: doc.code,
              comments: doc.comments,
              name: doc.name,
              price: "",
              stock: "",
            },
          );
          doc.price && setValue(this.$refs.price.$refs.input, doc.price);
          doc.stock && setValue(this.$refs.stock.$refs.input, doc.stock);
        })
        .catch(() => {
          this.$snackbar.show({ title: "Erro ao buscar dados, sem conexão" });
        });
    },

    getStateData() {
      const doc = Object.assign({}, this.doc),
        price = parseCurrency(doc.price, this.priceOptions),
        stock = parseCurrency(doc.stock, this.stockOptions);

      doc.price = price !== null ? price : 0;
      doc.stock = stock !== null ? stock : 0;

      return doc;
    },

    async onStart() {
      if (this.$route.params.docRef) {
        this.getDataFromApi(this.$route.params.docRef);
      } else {
        this.docRef = db.collection(COLLECTION_ITEMS).doc();
        this.editable = true;
        setTimeout(() => {
          this.$refs.name.focus();
        }, 250);
      }
    },

    async saveToFirestore() {
      try {
        const doc = this.getStateData();
        doc.updatedAt = this.$day().toJSON();

        if (this.inserting) {
          doc.createdAt = doc.updatedAt;
        }

        await firestore.setItem(this.docRef, doc);

        this.editable = false;
        this.inserting = false;
        this.$snackbar.show({ title: "Dados salvos com sucesso" });
      } catch (e) {
        if (e.code && e.code === "code_exists") {
          this.codeError = e.msg;
        }
        this.$snackbar.show({
          title: e.msg || "Erro ao salvar dados, sem conexão",
        });
      }
    },

    validate() {
      requestAnimationFrame(() => {
        if (this.$refs.form.validate()) {
          this.saveToFirestore();
        }
      });
    },
  },
};
</script>
